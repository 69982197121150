import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./InstagramPost.module.scss";
import { useTranslation } from "react-i18next";

type InstagramPostProps = {
  contentImages: { asset: { url: string } }[]; // Sanity image references
  description: { _key: string; value: string }[]; // Internationalized description array
};

const InstagramPost: React.FC<InstagramPostProps> = ({
  contentImages,
  description,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { t, i18n } = useTranslation();

  // Function to get the description for the current locale
  const getDescriptionForLocale = (locale: string) => {
    const localizedDescription = description?.find(
      (desc) => desc._key === locale
    );
    return localizedDescription
      ? localizedDescription.value
      : t("description_not_available");
  };

  // Get the description based on the current language from i18next
  const currentLocale = i18n.language; // E.g., 'en', 'no', 'es', etc.
  const descriptionText = getDescriptionForLocale(currentLocale);

  const descriptionParagraphs = descriptionText?.split("\n").filter((p) => p);

  const toggleDescription = () => setIsExpanded(!isExpanded);

  const truncatedDescription = descriptionParagraphs?.slice(0, 1);
  const fullDescription = descriptionParagraphs?.slice(1);

  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
  };

  return (
    <div className={styles.instagramPost}>
      {/* Post Header */}
      <header className={styles.postHeader}>
        <img
          src={"/images/icons/mag.jpg"}
          alt="profile"
          className={styles.profilePic}
        />
        <div className={styles.headerInfo}>
          <p className={styles.username}>
            {t("magazine")}
            <img
              src={"/images/icons/verified.svg"}
              alt="profile"
              style={{
                height: "14px",
                width: "14px",
                marginBottom: "-2px",
                marginLeft: "5px",
              }}
            />
          </p>
          <p className={styles.timestamp}>{t("link_in_bio")}</p>
        </div>
        <img
          src={"/images/icons/more.png"}
          alt="profile"
          style={{ marginLeft: "auto", height: "20px", width: "20px" }}
        />
      </header>

      {/* Slider for the images */}
      <div className={styles.postContent}>
        <Slider {...sliderSettings}>
          {contentImages?.map((image, index) => (
            <div key={index}>
              <img src={image.asset.url} alt={`Instagram post ${index}`} />
            </div>
          ))}
        </Slider>
      </div>

      <div className={styles.postFooter}>
        <div className={styles.icons}>
          <img src="/images/icons/Like.png" alt="Like" />
          <img src="/images/icons/Comment.png" alt="Comment" />
          <img src="/images/icons/Share.png" alt="Share" />
          <img
            src="/images/icons/Bookmark.png"
            alt="Save"
            style={{ marginLeft: "auto" }}
          />
        </div>
        <p className={styles.likes}>323 {t("likes").toLowerCase()}</p>

        {/* Post description */}
        <div className={styles.description}>
          <b>{t("magazine").toLowerCase()} </b>
          {truncatedDescription?.map((paragraph, index) =>
            index === 0 ? paragraph : <p key={index}>{paragraph}</p>
          )}

          {/* Show the rest of the description if expanded */}
          {isExpanded &&
            fullDescription?.map((paragraph, index) => (
              <p key={index}>{paragraph}</p>
            ))}

          {/* Show "Show More" button only if not expanded and there is more text */}
          {!isExpanded && fullDescription?.length > 0 && (
            <button
              onClick={toggleDescription}
              className={styles.showMoreButton}
            >
              {" "}
              ... {t("more").toLowerCase()}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default InstagramPost;
