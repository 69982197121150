export const ADS_QUERY = `*[_type == "ad" && slug.current == $slug][0]{
    _id,
    title,
    slug,
    publishedAt,
    videoLink,
    quotes[]{
      text,
      author
    },
    bannerImage{
      asset -> {
        url
      }
    },
    footerImage{
      asset -> {
        url
      }
    },

    albumCover{
        asset -> {
            url
     }
    },
    
    images_and_text[]{
      ...,
      _type == "block" => {
        children[]{
          text,
          _type,
          marks
        }
      },
      _type == "image" => {
        asset -> {
          url
        }
      },
      _type == "title" => {
        title[]{
          _key,
          value
        }
      }
    }
  }`;
