import React, { useEffect, useState } from "react";
import styles from "./PublicityPhotos.module.scss";
import { Masonry } from "@mui/lab";
import { useTranslation } from "react-i18next";

interface ImageWithRatioProps {
  src: string;
  alt: string;
  download: { image: string; video: string };
  currentProject: string;
  onDownloadClick: () => void;
}

const ImageWithRatio: React.FC<ImageWithRatioProps> = ({
  src,
  alt,
  download,
  currentProject,
  onDownloadClick,
}) => {
  const { t } = useTranslation();
  return (
    <div>
      {src.endsWith(".mp4") ? (
        <video width="100%" height="auto" controls>
          <source src={src} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ) : (
        <img src={src} alt={alt} />
      )}
      <div
        className={styles.imagesSectionImageSubtitle}
        onClick={onDownloadClick}
      >
        {t("download")} {src.endsWith(".mp4") ? t("video") : t("image")}
      </div>
    </div>
  );
};

const ImagePopup: React.FC<{
  images: { asset: { url: string } }[];
  currentIndex: number;
  onClose: () => void;
  onNext: () => void;
  onPrevious: () => void;
  onDownloadClick: () => void;
}> = ({
  images,
  currentIndex,
  onClose,
  onNext,
  onPrevious,
  onDownloadClick,
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "ArrowRight") {
        onNext();
      } else if (event.key === "ArrowLeft") {
        onPrevious();
      }
    };

    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [onNext, onPrevious]);

  return (
    <div className={styles.fullScreenImageContainer}>
      <div className={styles.backgroundBlur}></div>
      <div className={styles.fullScreenImage}>
        <div className={styles.closeButton} onClick={onClose}></div>

        <img
          src={images[currentIndex]?.asset.url}
          alt={`Publicity Image ${currentIndex}`}
          className={styles.popupImage}
        />
        <div
          className={styles.imagesSectionImageSubtitle}
          onClick={onDownloadClick}
        >
          {t("download")} {t("image")}
        </div>
      </div>
    </div>
  );
};

interface ImagesSectionProps {
  title: string;
  download: { video: string; image: string };
  colors: string[];
  currentProject: string;
  images: {
    asset: { url: string };
    _type: string;
  }[];
}

export const ImagesSection: React.FC<ImagesSectionProps> = ({
  title,
  download,
  colors,
  currentProject,
  images,
}) => {
  const [columns, setColumns] = useState(3);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [currentIndex, setCurrentIndex] = useState<number | null>(null);

  useEffect(() => {
    const updateColumns = () => {
      if (window.innerWidth <= 768) {
        setColumns(1);
      } else if (window.innerWidth <= 2000) {
        setColumns(2);
      } else {
        setColumns(3);
      }
    };

    updateColumns();
    window.addEventListener("resize", updateColumns);

    return () => {
      window.removeEventListener("resize", updateColumns);
    };
  }, []);

  const handleImageClick = (imageUrl: string, index: number) => {
    setSelectedImage(imageUrl);
    setCurrentIndex(index);
  };

  const handleClosePopup = () => {
    setSelectedImage(null);
  };

  const handleNextImage = () => {
    if (currentIndex !== null) {
      setCurrentIndex((currentIndex + 1) % images.length);
    }
  };

  const handlePreviousImage = () => {
    if (currentIndex !== null) {
      setCurrentIndex((currentIndex - 1 + images.length) % images.length);
    }
  };

  const createHandleImageDownload = (src: string) => () => {
    const fileName = src.endsWith(".mp4") ? "video.mp4" : "image.jpg";
    fetch(src)
      .then((res) => res.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  };

  return (
    <div className={styles.imagesSection}>
      <Masonry columns={columns} spacing={1}>
        {images?.map((image, index) => (
          <div
            key={image.asset.url}
            onClick={() => handleImageClick(image?.asset.url, index)}
            className={styles.imagesSectionImage}
          >
            <ImageWithRatio
              src={image.asset.url}
              alt="Publicity Image/Video"
              download={download}
              currentProject={currentProject}
              onDownloadClick={createHandleImageDownload(image?.asset.url)}
            />
          </div>
        ))}
      </Masonry>

      {selectedImage !== null && currentIndex !== null && (
        <ImagePopup
          images={images}
          currentIndex={currentIndex}
          onClose={handleClosePopup}
          onNext={handleNextImage}
          onPrevious={handlePreviousImage}
          onDownloadClick={createHandleImageDownload(selectedImage)}
        />
      )}
    </div>
  );
};
