import React, { useEffect, useState } from "react";
import styles from "./Planbar.module.scss";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import dayplanData from "../../dayplan.json"; // Import the local JSON file

export const Planbar = () => {
  const [active, setActive] = useState(false);
  const project = dayplanData.projects[0]; // Select "Engel" project
  const { t } = useTranslation();

  useEffect(() => {
    if (active) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [active]);

  const isCurrentlyGoing = (startTime: string, endTime: string) => {
    const now = new Date(); // Get current local time

    const start = new Date(startTime); // Use the timestamp directly
    const end = new Date(endTime); // Use the timestamp directly

    return now >= start && now <= end; // Compare local times
  };

  return (
    <div className={styles.adbar}>
      <div
        className={`${styles.burger} ${active ? styles.active : ""}`}
        onClick={() => setActive(!active)}
      >
        <div className={styles.line1}></div>
        <div className={styles.line2}></div>
        <div className={styles.line3}></div>
      </div>
      <div className={styles.titles}>
        <h1>Engel</h1>
        <p>By Indieclimb</p>
      </div>
      <div className={`${styles.expanded} ${active && styles.expandedMenu}`}>
        <h1>Dagsplan</h1>
        {project.scenes.map((scene, index) => (
          <div className={styles.scene} key={index}>
            {isCurrentlyGoing(
              scene.scene.timestampStart,
              scene.scene.timestampEnd
            ) && <div className={styles.pill}>Pågår nå</div>}
            <p>
              {new Intl.DateTimeFormat("en-GB", {
                hour: "2-digit",
                minute: "2-digit",
              }).format(new Date(scene.scene.timestampStart))}{" "}
              –{" "}
              {new Intl.DateTimeFormat("en-GB", {
                hour: "2-digit",
                minute: "2-digit",
              }).format(new Date(scene.scene.timestampEnd))}
            </p>
            <h2>{scene.scene.title}</h2>
            <ul>
              {scene.scene.description.map((desc, descIndex) => (
                <li key={descIndex}>
                  <p>{desc}</p>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};
