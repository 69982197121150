import React from "react";
import { Icon } from "../../components/Icon/Icon.component";
import styles from "./SocialFooterSection.module.scss";
import { useTranslation } from "react-i18next";

export const SocialFooterSection = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className={styles.socialMedia}>
        <p>{t("listen_now")}</p>
        <div className={styles.iconsContainer}>
          <div className={styles.socialMediaMusicServices}>
            {" "}
            <a
              href={"https://open.spotify.com/artist/5wk5mLirrHPNbkvor0f5S2"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={styles.socialMediaBadge}>
                <Icon name={"spotify"} color={"white"} bg={false} />
              </div>
            </a>
            <a
              href={"https://music.apple.com/en/artist/indieclimb/1273179739"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={styles.socialMediaBadge}>
                <Icon name={"apple"} color={"white"} bg={false} />
              </div>
            </a>
            <a
              href={"https://www.deezer.com/en/artist/13039421"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={styles.socialMediaBadge}>
                <Icon name={"deezer"} color={"white"} bg={false} />
              </div>
            </a>
            <a
              href={"https://www.youtube.com/@indieclimb"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={styles.socialMediaBadge}>
                <Icon name={"youtube"} color={"white"} bg={false} />
              </div>
            </a>
          </div>
          <div className={styles.socialMediaPlatforms}>
            <a href={"item.url"} target="_blank" rel="noopener noreferrer">
              <div className={styles.socialMediaBadge}>
                <Icon
                  name={"instagram"}
                  color={"white"}
                  bg={false}
                  isSocialMedia={true}
                />
              </div>
            </a>
            <a href={"item.url"} target="_blank" rel="noopener noreferrer">
              <div className={styles.socialMediaBadge}>
                <Icon
                  name={"facebook"}
                  color={"white"}
                  bg={false}
                  isSocialMedia={true}
                />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
