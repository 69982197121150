import React from "react";
import styles from "./SongSection.module.scss";

import { useTranslation } from "react-i18next";

interface SongSectionProps {
  mainColor: string;
  releaseDate: string;
  albumCover: string;
  lowQualityBlur: string;
}

const SongSection: React.FC<SongSectionProps> = ({
  mainColor,
  releaseDate,
  albumCover,
  lowQualityBlur,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.songSection}>
      <div
        className={styles.songSectionTextWrapper}
        style={{ backgroundColor: mainColor }}
      >
        <div className={styles.songSectionText}>
          <h1>{t("the_song")}</h1>
          <p className={styles.releaseDate}>
            {t("out_on_all_platforms")}:{" "}
            {new Date(releaseDate)
              .toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })
              .replace(/\//g, ".")}
          </p>
          <p>
            LET ME GO is a journey into 2000s techno and modern electronica. The
            music video represents a six-minute journey into youthful energy and
            nostalgic liberation. The soundtrack captures a distinct sound era,
            delivered through dreamlike and innovative sound, with emotional
            resonance combined with invigorating, trance-like rhythms. Through
            the soundtrack and a music video that resembles a short film in
            itself, Indieclimb engages in dialogue with other genres and eras,
            while anchoring the music in today's electronic scene.
          </p>
        </div>
      </div>
      <div
        className={styles.songSectionCover}
        style={{ backgroundColor: mainColor }}
      >
        <button
          className={styles.neoButton}
          // onClick={handleButtonClick}
        ></button>
        <div className={styles.blurryBackground}>
          {lowQualityBlur && <img src={lowQualityBlur} alt="Album Cover" />}
        </div>
        <div className={styles.spinningRecord}>
          <img src={albumCover} alt="Album Visual" />
          <img
            className={styles.albumOverlay}
            src="https://cdn.sanity.io/images/m4jodx2s/production/8ed20b56d46d463075ac04ee6f054cfc4e81840d-2160x2160.png"
            alt=""
          />
          <div className={styles.blackHole}></div>
        </div>
      </div>
    </div>
  );
};

export default SongSection;
