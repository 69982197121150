import React, { useEffect } from "react";

const MailchimpForm = () => {
  useEffect(() => {
    // Add Mailchimp's validation script
    const script = document.createElement("script");
    script.src = "//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js";
    script.async = true;
    document.body.appendChild(script);

    // Add CSS file to the document head
    const link = document.createElement("link");
    link.href = "//cdn-images.mailchimp.com/embedcode/classic-061523.css";
    link.rel = "stylesheet";
    link.type = "text/css";
    document.head.appendChild(link);

    // Mailchimp-specific setup
    window.fnames = [];
    window.ftypes = [];
    window.fnames[0] = "EMAIL";
    window.ftypes[0] = "email";
    window.fnames[1] = "FNAME";
    window.ftypes[1] = "text";
    window.fnames[2] = "MMERGE2";
    window.ftypes[2] = "imageurl";
    window.fnames[4] = "MMERGE4";
    window.ftypes[4] = "text";

    return () => {
      document.body.removeChild(script);
      document.head.removeChild(link);
    };
  }, []);

  return (
    <div id="mc_embed_shell">
      <div id="mc_embed_signup">
        <form
          action="https://indieclimb.us22.list-manage.com/subscribe/post?u=d613d49da55667ee4f6bbc76d&amp;id=b78f79225f&amp;f_id=003fdae1f0"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          target="_blank"
          noValidate
        >
          <div id="mc_embed_signup_scroll">
            <h2>Subscribe</h2>
            <div className="indicates-required">
              <span className="asterisk">*</span> indicates required
            </div>
            <div className="mc-field-group">
              <label htmlFor="mce-EMAIL">
                Email Address <span className="asterisk">*</span>
              </label>
              <input
                type="email"
                name="EMAIL"
                className="required email"
                id="mce-EMAIL"
                required
              />
            </div>
            <div className="mc-field-group">
              <label htmlFor="mce-FNAME">First Name</label>
              <input type="text" name="FNAME" className="text" id="mce-FNAME" />
            </div>
            <div className="mc-field-group">
              <label htmlFor="mce-MMERGE2">
                Profile Image <span className="asterisk">*</span>
              </label>
              <input
                type="imageurl"
                name="MMERGE2"
                className="required imageurl"
                id="mce-MMERGE2"
                required
              />
            </div>
            <div className="mc-field-group">
              <label htmlFor="mce-MMERGE4">Last Name</label>
              <input
                type="text"
                name="MMERGE4"
                className="text"
                id="mce-MMERGE4"
              />
            </div>
            <div id="mce-responses" className="clear">
              <div
                className="response"
                id="mce-error-response"
                style={{ display: "none" }}
              ></div>
              <div
                className="response"
                id="mce-success-response"
                style={{ display: "none" }}
              ></div>
            </div>
            <div
              aria-hidden="true"
              style={{ position: "absolute", left: "-5000px" }}
            >
              <input
                type="text"
                name="b_d613d49da55667ee4f6bbc76d_b78f79225f"
                tabIndex={-1}
                value=""
              />
            </div>
            <div className="clear">
              <input
                type="submit"
                name="subscribe"
                id="mc-embedded-subscribe"
                className="button"
                value="Subscribe"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default MailchimpForm;
