import React from "react";
import styles from "./PressReleaseSection.module.scss";
import { PortableText, PortableTextComponents } from "@portabletext/react";
import imageUrlBuilder from "@sanity/image-url";
import { client } from "../../sanityClient";
import { useTranslation } from "react-i18next";

// Set up image URL builder
const builder = imageUrlBuilder(client);
function urlFor(source: any) {
  return builder.image(source);
}

interface PressReleaseSectionProps {
  pressRelease: Array<{
    _type: string;
    children?: Array<{
      text: string;
      _type: string; // Added _type to children as required
      marks: string[];
    }>;
    image?: {
      asset: {
        _ref: string;
      };
    };
    alt?: string;
    caption?: string;
    url?: string;
  }>;
}

const getFirstSlugPartFromUrl = () => {
  const url = window.location.pathname;
  const urlParts = url.split("/");
  if (urlParts[1] === "en") return "com";
  return urlParts[1];
};

// Define components for PortableText (bold, italic, etc.)
const components: PortableTextComponents = {
  marks: {
    strong: ({ children }) => <strong>{children}</strong>,
    italic: ({ children }) => <i>{children}</i>,
    em: ({ children }) => <em>{children}</em>,
  },
  block: {
    h2: ({ children }) => <h2>{children}</h2>,
    normal: ({ children }) => <p>{children}</p>,
  },
};

// Main component for rendering the press release section
const PressReleaseSection: React.FC<PressReleaseSectionProps> = ({
  pressRelease,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.pressReleaseSection}>
      <div className={styles.pressReleaseBar}>
        <div className={styles.pressReleaseBarSearch}>
          <img
            src="https://cdn.sanity.io/images/m4jodx2s/production/c61a19f8cb30baa7c14a70228334b8d9f7092f20-64x64.png"
            alt="Lock Icon"
          />
          <p>
            {t("magazine").toLocaleLowerCase()}.{getFirstSlugPartFromUrl()}
          </p>
        </div>
      </div>

      {pressRelease?.map((block, index) => {
        switch (block._type) {
          case "block": // Text block content
            return (
              <div key={index} className={styles.pressReleaseText}>
                {block.children && (
                  <PortableText value={block} components={components} />
                )}
              </div>
            );

          case "imageWithText": // Image with accompanying text
            return (
              <div key={index} className={styles.pressReleaseImage}>
                {block.image?.asset?._ref && (
                  <img
                    src={urlFor(block.image.asset).url()}
                    alt={block.alt || "Press Release Image"}
                  />
                )}
                {block.caption && <p>{block.caption}</p>}
              </div>
            );

          case "youtubeEmbed": // YouTube embed block
            return (
              <div key={index} className={styles.pressReleaseYoutubeEmbed}>
                {block.url ? (
                  <iframe
                    width="560"
                    height="315"
                    src={block.url.replace("watch?v=", "embed/")}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="YouTube Video"
                  />
                ) : (
                  <p>{t("youtube_url_not_available")}</p>
                )}
              </div>
            );

          case "spotifyEmbed": // Spotify embed block
            return (
              <div key={index} className={styles.pressReleaseSpotifyEmbed}>
                {block.url ? (
                  <iframe
                    src={`https://open.spotify.com/embed/${block.url.split("/").slice(-2)[0]}/${block.url
                      .split("/")
                      .pop()}`}
                    width="100%"
                    height="152"
                    frameBorder="0"
                    allow="encrypted-media"
                    title="Spotify Embed"
                  />
                ) : (
                  <p>{t("spotify_url_not_available")}</p>
                )}
              </div>
            );

          default:
            return null;
        }
      })}
    </div>
  );
};

export default PressReleaseSection;
